import { trackEvent, trackPage } from 'utils/analytics'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { useContext } from 'react'
import { useSessionId } from 'utils/analytics/session'
import { useRouter } from 'next/router'

const useTriggerEvents = () => {
  const {
    state: {
      analyticsInstance,
      trackingData,
      isPageLoaded,
      auth0_email,
      auth0_user_id,
      profile_id,
      config,
      quiz_name,
      quiz_page,
      quiz_score,
    },
  } = useContext(AnalyticsContext)

  // Will reset session start time and create new session if last event was >30 min prior
  useSessionId()
  const router = useRouter()

  const fireAndForget = (event) => {
    if (!isPageLoaded) return
    trackEvent(analyticsInstance, config, {
      ...trackingData,
      auth0_email,
      auth0_user_id,
      profile_id,
      ...event,
      ...(quiz_name && {
        quiz_name: quiz_name,
      }),
      ...(quiz_page && {
        quiz_page: event?.quiz_page || quiz_page,
      }),
      ...(quiz_score && {
        quiz_score: quiz_score,
      }),
      event_pzn: router?.query?.pzn === 'on',
    })
  }

  const fireAndForgetPage = (event) => {
    if (!isPageLoaded) return
    trackPage(analyticsInstance, config, {
      ...trackingData,
      auth0_email,
      auth0_user_id,
      profile_id,
      ...event,
      ...(quiz_name && {
        quiz_name: quiz_name,
      }),
      ...(quiz_page && {
        quiz_page: event?.quiz_page || quiz_page,
      }),
      ...(quiz_score && {
        quiz_score: quiz_score,
      }),
      event_pzn: router?.query?.pzn === 'on',
    })
  }

  const waitForNavigation = async (event) => {
    if (!isPageLoaded) return
    await trackEvent(analyticsInstance, config, {
      ...trackingData,
      auth0_email,
      auth0_user_id,
      profile_id,
      ...event,
      ...(quiz_name && {
        quiz_name: quiz_name,
      }),
      ...(quiz_page && {
        quiz_page: quiz_page,
      }),
      ...(quiz_score && {
        quiz_score: quiz_score,
      }),
      event_pzn: router?.query?.pzn === 'on',
    })
  }

  return { fireAndForget, waitForNavigation, fireAndForgetPage }
}
export { useTriggerEvents }
